import { IImage } from "interfaces/IImage";
import api from "../api";
import qs from "querystring";

export default {
  async getImages(
    page = 0,
    limit = "15",
    search?: string,
    sort?: string,
    order?: string
  ) {
    const query = qs.stringify({ page, limit, search, sort, order });
    const res = await api.get(`/images?${query}`);
    if (res.ok) {
      const data = await res.json();
      return data;
    }
    return [];
  },
  async addImage(image: IImage) {
    const res = await api.post("/images", { body: image });
    const data = await res.json();
    return data;
  },
  async editImage(oldImage: IImage, newImage: IImage) {
    const res = await api.put(`/images/${oldImage._id}`, { body: newImage });
    const data = await res.json();
    return data;
  },
  async deleteImage(image: IImage) {
    const res = await api.del(`/images/${image._id}`);
    const data = await res.json();
    return data;
  },

  count: async (search: string) => {
    const res = await api.get(`/count/images?q=${search}`);
    const data = await res.json();
    return data;
  },
};
