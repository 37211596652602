import React, { useEffect } from 'react'
import { IDeviceInfo } from 'interfaces/iDeviceInfo'
import { If, Then, Else } from 'react-if'
import { UnknownListItem } from './UnknownListItem'
import {
  Container,
  Header,
  Loader,
  Pagination,
  Select,
  Table,
} from 'semantic-ui-react'
import { IConfiguration } from 'interfaces/IConfiguration'
import { SearchForm } from 'components/base/SearchForm'

interface UnknownPageProps {
  activePage: number
  isLoading: boolean
  limit: number
  order: 'ascending' | 'descending';
  sortTitle: string;
  onSort: (header: string) => void,
  onItemDelete: (unknown: IDeviceInfo, e: React.MouseEvent<HTMLButtonElement>) => void
  onItemSave: (unknown: IDeviceInfo) => void
  configuration?: IConfiguration
  onPaginationChange: (page: number) => void
  onConfigurationSave: (configuration: IConfiguration) => void
  onLimitChange: (limit: number) => void
  totalPages: number
  unknowns: IDeviceInfo[]
  onSearchChange: (q: string) => void
}

export const UnknownPage = (props: UnknownPageProps) => {
  const { onSort, order, sortTitle } = props;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container>
      <Loader active={props.isLoading} />

      <If condition={props.unknowns.length === 0}>
        <Then>
          <Header as='h3' style={{ marginTop: '1em' }} color='grey'>
            No unknown devices found.
          </Header>
        </Then>
        <Else>
          <Header as='h3' style={{ marginTop: '1em' }} color='grey'>
            These devices were unable to find configurations
          </Header>
        </Else>
      </If>
      <SearchForm
        onSearchChange={props.onSearchChange}
        placeholderText='Search for name, manufacturer, mac, ip'
      />

      <If condition={props.unknowns.length !== 0}>
        <Then>
          <Table celled fixed selectable sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  className='bg-primary-dark' colSpan={4}
                  sorted={sortTitle === 'name' ? order : null}
                  onClick={() => onSort('name')}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  className='bg-primary-dark' colSpan={5}
                  sorted={sortTitle === 'manufacturer' ? order : null}
                  onClick={() => onSort('manufacturer')}
                >
                  Manufacturer
                </Table.HeaderCell>
                <Table.HeaderCell
                  className='bg-primary-dark' colSpan={3}
                  sorted={sortTitle === 'mac' ? order : null}
                  onClick={() => onSort('mac')}
                >
                  MAC
                </Table.HeaderCell>
                <Table.HeaderCell
                  className='bg-primary-dark' colSpan={3}
                  sorted={sortTitle === 'ip' ? order : null}
                  onClick={() => onSort('ip')}
                >
                  IP
                </Table.HeaderCell>
                <Table.HeaderCell
                  className='bg-primary-dark' colSpan={1}
                >
                  Delete
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {props.unknowns.length !== 0 &&
                props.unknowns.map((unknown, index) => {
                  return (
                    <UnknownListItem
                      key={index}
                      unknown={unknown}
                      onClick={props.onItemSave}
                      onRemove={props.onItemDelete}
                    />
                  )
                })}
            </Table.Body>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <label style={{ marginRight: '0.3rem' }}>Items per page: </label>
            <Select
              style={{ minWidth: '1em', height: '42px' }}
              options={[
                { text: '15', value: 15 },
                { text: '25', value: 25 },
                { text: '50', value: 50 },
              ]}
              value={props.limit}
              onChange={(e, select) =>
                props.onLimitChange(parseInt(select.value as any))
              }
            />
            <Pagination
              style={{ boxShadow: '0 0' }}
              activePage={props.activePage}
              totalPages={props.totalPages}
              onPageChange={(e, p) =>
                props.onPaginationChange(parseInt(p.activePage as any))
              }
            />
          </div>
        </Then>
        <Else>
        </Else>
      </If>
    </Container>
  )
}
