import React, { useEffect, useState } from 'react'
import { ConfigurationsPage } from './ConfigurationsPage'
import { useResources } from 'context/resource-provider'
import ImageService from '../../services/ImageService'
import HospitalService from '../../services/HospitalService'
import ConfigurationService from '../../services/ConfigurationService'

interface ConfigurationsControllerProps {
  path?: string
  location?: any
}

export function ConfigurationsController(props: ConfigurationsControllerProps) {
  const {
    setConfigurations,
    setImages,
    setHospitals,
  } = useResources()
  const [isLoading, setIsLoading] = useState(true)
  const [activePage, setActivePage] = useState(1)
  const [limit, setLimit] = useState(15)
  const [search, setSearch] = useState('')
  const [totalPages, setTotalPages] = useState(1)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const [order, setOrder] = useState<'ascending' | 'descending'>(null);
  const [sort, setSort] = useState<string>('');

  useEffect(() => {
    setIsLoading(true)
    Promise.all([
      ConfigurationService.getConfigurations(),
      HospitalService.getHospitals(),
      ImageService.getImages(0, '-1'),
    ])
      .then(([configurations, hospitals, images]) => {
        setConfigurations(configurations)
        setImages(images)
        setHospitals(hospitals)
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }, [setConfigurations, setImages, setHospitals])

  useEffect(() => {
    setIsLoading(false)
    Promise.all([
      ConfigurationService.getConfigurations(
        activePage - 1,
        limit.toString(),
        search,
        sort,
        order === 'ascending' ? '-1' : '1'
      ),
      ConfigurationService.count(search),
    ])
      .then(([configs, { count }]) => {
        setConfigurations(configs)
        const lastPage = Math.ceil(parseInt(count) / limit)
        setTotalPages(lastPage)
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }, [activePage, limit, search, order])

  const handleSearchChange = (s: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    const id = setTimeout(() => {
      setSearch(s)
    }, 200)
    setTimeoutId(id)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handlePaginationChange = (e: any, page: number) => {
    setActivePage(page)
  }

  const hanldeSort = (title: string) => {
    setSort(title);
    setOrder(order === 'ascending' ? 'descending' : 'ascending');
  }

  const handleLimitChange = perPage => {
    setLimit(perPage)
    setActivePage(1)
  }

  return (
    <ConfigurationsPage
      isLoading={isLoading}
      onPagination={handlePaginationChange}
      activePage={activePage}
      onSearchChange={handleSearchChange}
      totalPages={totalPages}
      limit={limit}
      setLimit={handleLimitChange}
      order={order}
      sortTitle={sort}
      onSort={hanldeSort}
    />
  )
}
