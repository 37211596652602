import React from "react";
import { Router } from "@reach/router";
import Navbar from "components/base/Navbar";
import { ConfigurationsController } from "components/configurations/ConfigurationsController";
import { HospitalsController } from "components/hospitals/HospitalsController";
import { ImagesController } from "components/images/ImagesController";
import { ProtectedRoutes } from "components/base/ProtectedRoutes";
import { UnknownController } from "components/unknown/UnknownController";
import { LogsController } from "components/logs/LogsController";
import { IpAliasesController } from "components/aliases/IpAliasesController";
import './App.css';
import { ResourceProvider } from "context/resource-provider";
import { AuthProvider } from "context/auth-provider";

const App = () => {
  return (
    <div className="App">
      <AuthProvider>
        <ResourceProvider>
          <Navbar brand="Discovery-Server" />
          <div className="container" style={{ paddingTop: "4em", paddingBottom: "4em" }}>
            <Router>
              <ProtectedRoutes path="/">
                <ConfigurationsController path="/" />
                <ConfigurationsController path="/configurations" />
                <HospitalsController path="/hospitals" />
                <ImagesController path="/images" />
                <IpAliasesController path="/aliases" />
                <UnknownController path="/unknown" />
                <LogsController path="/logs" />
              </ProtectedRoutes>
            </Router>
          </div>
        </ResourceProvider>
      </AuthProvider>
    </div>
  );
};

export default App;
