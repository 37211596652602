import React from 'react'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'

interface DeleteConformationProps {
  title: string,
  open: boolean,
  onExit: () => void;
  onDelete?: () => void
}

export function DeleteConfirmation(props: DeleteConformationProps) {
  const { title, open, onDelete, onExit } = props

  return (
    <Modal closeIcon
      open={open}
      onClose={onExit}
      size='mini'
    >
      <Header color="grey">
        <Icon name='warning' className="accent" />
        Are you sure?
      </Header>
      <Modal.Content>
        <p><strong>{title}</strong> will be deleted!</p>
      </Modal.Content >
      <Modal.Actions>
        <Button basic color='grey'
          onClick={onExit}
        >
          <Icon name='remove' /> Cancel
        </Button>
        <Button basic color='orange' onClick={onDelete}>
          <Icon name='trash' /> Delete
        </Button>
      </Modal.Actions>
    </Modal >
  )
}
