import React from 'react'
import { IDeviceInfo } from 'interfaces/iDeviceInfo'
import { Button, Table } from 'semantic-ui-react'

interface IUnknownListItemProps {
  unknown: IDeviceInfo
  onClick?: (unknown: IDeviceInfo) => void
  onRemove?: (unknown: IDeviceInfo, e: React.MouseEvent<HTMLButtonElement>) => void
}

export function UnknownListItem(props: IUnknownListItemProps) {
  const { unknown, onRemove } = props

  return (
    <Table.Row className='striped' onClick={() => props.onClick && props.onClick(unknown)}>
      <Table.Cell colSpan={4} singleLine>{unknown.name}</Table.Cell>
      <Table.Cell colSpan={5} singleLine>{unknown.manufacturer}</Table.Cell>
      <Table.Cell colSpan={3}>{unknown.mac}</Table.Cell>
      <Table.Cell colSpan={3}>{unknown.ip}</Table.Cell>
      <Table.Cell
        style={{ padding: 0 }}
        colSpan={1} textAlign='center'>
        <Button
          basic
          icon='close'
          compact
          color='orange'
          onClick={(e) => onRemove(unknown, e)}
        />
      </Table.Cell>
    </Table.Row>
  )
}
