import React from "react";
import { IHospital } from "interfaces/IHospital";
import { Table } from "semantic-ui-react";

interface ListItemProps {
  hospital: IHospital;
  onEdit: any;
  onDelete: any;
}
export function HospitalListItem(props: ListItemProps) {
  const { hospital, onEdit } = props;

  return (
    <Table.Row className='striped' onClick={onEdit}>
      <Table.Cell colSpan={8}>{hospital.name}</Table.Cell>
      <Table.Cell colSpan={8}>{hospital.ip}</Table.Cell>
    </Table.Row>
  );
}
