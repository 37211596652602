import React from "react";
import { FormEntry } from "../base/FormEntry";
import { BaseForm } from "../base/BaseForm";
import { useImage } from "../../hooks/useImage";
import { Button, Header, Icon, Modal } from "semantic-ui-react";

interface ImageFormProps extends ReturnType<typeof useImage> {
  onSave?: any;
  onExit?: any;
  onDelete?: any;
  open: boolean;
  formTitle;
}

export function ImageForm(props: ImageFormProps) {
  const {
    name,
    setName,
    url,
    setUrl,
    magnet,
    setMagnet,
    isValid,
    onSave,
    onExit,
    onDelete,
    open,
    formTitle
  } = props;

  return (
    <>
      <Modal closeIcon
        open={open}
        onClose={onExit}
      >
        <Header color="grey">
          <Icon name='disk' className="accent" />
          {formTitle}
        </Header>
        <Modal.Content>
          <BaseForm title={name} onSave={onSave} onExit={onExit} isValid={isValid}>
            <FormEntry
              autoFocus={true}
              label="Title"
              value={name}
              placeholder="Image title"
              onChange={setName}
            />
            <FormEntry
              label="URL"
              value={url}
              placeholder="Image URL"
              onChange={setUrl}
            />
            <FormEntry
              label="Magnet"
              value={magnet}
              placeholder="Image Magnet Link"
              onChange={setMagnet}
            />
          </BaseForm>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color='grey' onClick={onExit}>
            <Icon name='remove' /> Cancel
          </Button>
          {onDelete && <Button basic color='orange' onClick={onDelete}>
            <Icon name='trash' /> Delete
          </Button>}
          <Button basic color='green' onClick={onSave}>
            <Icon name='checkmark' /> Save
        </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
