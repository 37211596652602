import React from "react";
import { IpAliasForm } from "./IpAliasForm";
import { useIpAlias } from "hooks/useIpAlias";
import AliasService from "services/IpAliasService";
import { useResources } from "context/resource-provider";

export interface AddIpAliasControllerProps {
  open: boolean;
  onClose: any;
}

export function AddIpAliasController(props: AddIpAliasControllerProps) {
  const { open, onClose } = props;
  const { setAliases, setMessage } = useResources();
  const controller = useIpAlias();

  async function handleSave(e: any) {
    e.preventDefault();
    const response = await AliasService.addIpAlias(controller.ipAlias);
    setMessage({
      status: response,
      text: response ? "Alias Saved" : "Failed to save alias / check and retry",
      isNew: true
    });
    AliasService.getIpAliases().then(aliases => setAliases(aliases));
    controller.resetForm();
    onClose();
  }

  async function handleExit(e: any) {
    e.preventDefault();
    controller.resetForm();
    onClose();
  }

  return <IpAliasForm
    {...controller}
    formTitle='Add New Alias'
    onExit={handleExit}
    onSave={handleSave}
    open={open} />
}
