import { useState, useCallback, useMemo } from "react";
import { IHospital } from "../interfaces/IHospital";

export function useHospital() {
  const [name, setName] = useState("");
  const [ip, setIp] = useState("");

  const hospital: IHospital = useMemo(() => {
    return {
      name,
      ip
    };
  }, [name, ip]);

  const resetHospital = useCallback(() => {
    setName("");
    setIp("");
  }, []);

  const setHospital = useCallback((hospital: IHospital) => {
    setName(hospital.name);
    setIp(hospital.ip);
  }, []);

  const isValid = useMemo(() => {
    return true;
  }, []);

  return {
    hospital,
    name,
    setName,
    ip,
    setIp,
    resetForm: resetHospital,
    setHospital,
    isValid
  };
}
