import React from "react";
import { IIpAlias } from "interfaces/IIpAlias";
import { Table } from "semantic-ui-react";

interface ListItemProps {
  ipAlias: IIpAlias;
  onEdit: any;
  onDelete: any;
}
export function IPAliasListItem(props: ListItemProps) {
  const { ipAlias, onEdit } = props;

  return (
    <Table.Row className='striped' onClick={onEdit}>
      <Table.Cell colSpan={3}>{ipAlias.title}</Table.Cell>
      <Table.Cell colSpan={6} singleLine>{ipAlias.from}</Table.Cell>
      <Table.Cell colSpan={7} singleLine>{ipAlias.to}</Table.Cell>
    </Table.Row >
  );
}
