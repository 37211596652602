import React from "react";
import { IImage } from "interfaces/IImage";
import { Table } from "semantic-ui-react";

interface ListItemProps {
  image: IImage;
  onEdit: any;
  onDelete: any;
}
export function ImageListItem(props: ListItemProps) {
  const { image, onEdit } = props;

  return (
    <>
      <Table.Row className='striped' onClick={onEdit}>
        <Table.Cell colSpan={3}>{image.name}</Table.Cell>
        <Table.Cell colSpan={6} singleLine>{image.url}</Table.Cell>
        <Table.Cell colSpan={7} singleLine>{image.magnet}</Table.Cell>
      </Table.Row>
    </>
  );
}
