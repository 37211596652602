import React from "react";
import { Form } from "semantic-ui-react";

interface BaseFormProps {
  isValid: boolean;
  title: string;
  onExit: any;
  onSave: any;
  children?: any;
  showCloseButton?: boolean;
  buttonLabel?: string;
}

export function BaseForm(props: BaseFormProps) {
  const {
    // isValid,
    title,
    children,
  } = props;

  return (
    <Form
      onSubmit={e => e.preventDefault()}
    >
      <h3>{title || "Untitled"}</h3>
      {children}
    </Form>
  );
}
