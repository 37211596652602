import React from "react";
import { Button, Card, Container, Grid } from "semantic-ui-react";
import { BaseForm } from "../base/BaseForm";
import { FormEntry } from "../base/FormEntry";
import { FormPassword } from "../base/FormPassword";

interface ILoginFormProps {
  username: string;
  password: string;
  errorMessage?: string;
  onUsernameChange: (e: string) => void;
  onPasswordChange: (e: string) => void;
  onLogin: any;
}

export function LoginForm(props: ILoginFormProps) {
  const {
    onUsernameChange,
    onPasswordChange,
    onLogin,
    username,
    password,
    errorMessage
  } = props;

  return (
    <Container >
      <Grid centered verticalAlign='middle' style={{ height: '50vh' }}>
        <Grid.Column computer={8} tablet={10} mobile={16} >
          <Card fluid>
            <Card.Content>
              <BaseForm
                showCloseButton={false}
                buttonLabel={"Login"}
                onExit={() => { }}
                onSave={onLogin}
                title="Login"
                isValid={true}
              >
                <FormEntry
                  label="Username"
                  value={username}
                  onChange={onUsernameChange}
                />
                <FormPassword
                  label="Password"
                  value={password}
                  onChange={onPasswordChange}
                />
                <p className="accent-dark">
                  <small>{errorMessage || ""}</small>
                </p>
                <Button
                  type="submit"
                  disabled={!username && !password}
                  onClick={onLogin}
                >
                  <strong>Login</strong>
                </Button>
              </BaseForm>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    </Container>
  );
}
