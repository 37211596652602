import React, { useState, useEffect } from 'react'
import { HospitalForm } from './HospitalForm'
import { IHospital } from '../../interfaces/IHospital'
import { useHospital } from '../../hooks/useHospital'
import { If, Then, Else } from 'react-if'
import { HospitalListItem } from 'components/base/HospitalListItem';
import HospitalService from "../../services/HospitalService";
import { useResources } from "context/resource-provider";
import { DeleteConfirmation } from 'components/base/DeleteConfirmation'


interface HospitalListItemControllerProps {
  hospital: IHospital
}
export function HospitalListItemController(
  props: HospitalListItemControllerProps
) {
  const { hospital } = props
  const [isEditMode, setIsEditMode] = useState(false)
  const controls = useHospital();
  const { setHospitals, setMessage } = useResources();
  const [deleteItem, setDeleteItem] = useState(null);

  useEffect(() => {
    controls.setHospital(hospital)
  }, [hospital]);

  const reloadHospitals = () => {
    HospitalService.getHospitals().then(hospitals => setHospitals(hospitals));
    setIsEditMode(false);
  }

  function handleEdit() {
    setIsEditMode(true)
  }

  function handleConfirmDelete() {
    setDeleteItem(hospital);
  }

  function handleDelete() {
    HospitalService.deleteHospital(deleteItem).then((response) => {
      setMessage({
        status: response?.ok === 1,
        text: response?.ok === 1 ? "Hospital Deleted" : "Failed to delete hospital",
        isNew: true
      });
      setDeleteItem(null);
      reloadHospitals();
    })
  }

  async function handleSave(e) {
    e.preventDefault()
    const response = await HospitalService.editHospital(
      hospital,
      Object.assign(controls.hospital)
    );
    setMessage({
      status: response?.ok === 1,
      text: response?.ok === 1 ? "Hospital Saved" : "Failed to save hospital",
      isNew: true
    });
    HospitalService.getHospitals().then(hospitals => {
      setHospitals(hospitals);
      reloadHospitals();
    });
  }

  function handleExit() {
    setIsEditMode(false)
  }

  return (
    <>
      <HospitalForm
        formTitle='Edit Hospital'
        {...controls}
        onSave={async e => await handleSave(e)}
        onExit={handleExit}
        onDelete={handleConfirmDelete}
        open={isEditMode}
      />
      <DeleteConfirmation
        title={"Hospital " + (deleteItem ? deleteItem.name : '')}
        open={deleteItem != null}
        onExit={() => setDeleteItem(null)}
        onDelete={handleDelete}
      />
      <HospitalListItem
        onEdit={handleEdit}
        onDelete={handleDelete}
        hospital={hospital}
      />
    </>
  )
}
