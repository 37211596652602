import React, { useState } from 'react'
import { IConfiguration } from '../../interfaces/IConfiguration'
import { ConfigurationForm } from './ConfigurationForm'
import { ConfigurationListItem } from 'components/base/ConfigurationListItem'
import { Table } from 'semantic-ui-react'
import { useResources } from 'context/resource-provider'
import ConfigurationService from '../../services/ConfigurationService'
import { DeleteConfirmation } from 'components/base/DeleteConfirmation'

interface ConfigurationListItemControllerProps {
  configuration: IConfiguration
}
export function ConfigurationListItemController({
  configuration,
}: ConfigurationListItemControllerProps) {
  const [isEditMode, setIsEditMode] = useState(false);
  const { setConfigurations, setMessage } = useResources();
  const [deleteItem, setDeleteItem] = useState(null);

  const reloadConfigurations = () => {
    ConfigurationService.getConfigurations().then(configs =>
      setConfigurations(configs)
    )
    setIsEditMode(false)
  }

  function handleEdit() {
    setIsEditMode(true)
  }

  function handleConfirmDelete(config: IConfiguration) {
    setDeleteItem(config);
  }

  async function handleDelete(config: IConfiguration) {
    const response = await ConfigurationService.deleteConfiguration(config._id);
    setMessage({
      status: response?.ok === 1,
      text: response?.ok === 1 ? "Configuration Deleted" : "Failed to delete configuration",
      isNew: true
    });
    setDeleteItem(null);
    reloadConfigurations()
  }

  async function handleSave(config: IConfiguration) {
    const response = await ConfigurationService.editConfiguration(configuration._id, config);
    setMessage({
      status: response?.ok === 1,
      text: response?.ok === 1 ? "Configuration Saved" : "Failed to save configuration",
      isNew: true
    });
    reloadConfigurations()
  }

  function handleExit() {
    setIsEditMode(false)
  }
  return (
    <>
      <ConfigurationForm
        formTitle='Edit Configuration'
        newDevice={null}
        configuration={configuration}
        onSave={handleSave}
        onExit={handleExit}
        onDelete={handleConfirmDelete}
        open={isEditMode}
      />
      <DeleteConfirmation
        title={"Configuration " + (deleteItem ? deleteItem.name : '')}
        open={deleteItem != null}
        onExit={() => setDeleteItem(null)}
        onDelete={() => handleDelete(deleteItem)}
      />
      <Table.Row className='striped' onClick={handleEdit}>
        <ConfigurationListItem configuration={configuration} />
      </Table.Row>
    </>
  )
}
