import React, { useEffect, useState } from "react";
import HospitalsPage from "./HospitalsPage";
import { useResources } from "context/resource-provider";
import HospitalService from "../../services/HospitalService";

interface HospitalsControllerProps {
  path?: string;
}

export function HospitalsController(props: HospitalsControllerProps) {
  const { hospitals, setHospitals } = useResources();
  const [isLoading, setIsLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const [order, setOrder] = useState<'ascending' | 'descending'>(null);
  const [sort, setSort] = useState<string>('');

  useEffect(() => {
    setIsLoading(true);
    Promise.all([HospitalService.getHospitals(
      activePage - 1,
      limit.toString(),
      search,
      sort,
      order === 'ascending' ? '-1' : '1'
    ),
    HospitalService.count(search)])
      .then(([hospitals, { count }]) => {
        setHospitals(hospitals);
        const lastPage = Math.ceil(parseInt(count) / limit);
        setTotalPages(lastPage)
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }, [activePage, limit, search, order]);

  const handlePaginationChange = (e?, page?) => {
    setActivePage(page);
  }

  const hanldeSort = (title: string) => {
    setSort(title);
    setOrder(order === 'ascending' ? 'descending' : 'ascending');
  }

  const handleLimitChange = (perPage) => {
    setLimit(perPage);
    setActivePage(1);
  }

  const handleSearchChange = (s: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    const id = setTimeout(() => {
      setSearch(s)
    }, 200)
    setTimeoutId(id)
  }

  return (
    <HospitalsPage
      isLoading={isLoading}
      onPagination={handlePaginationChange}
      activePage={activePage}
      totalPages={totalPages}
      limit={limit}
      setLimit={handleLimitChange}
      onSearchChange={handleSearchChange}
      order={order}
      sortTitle={sort}
      onSort={hanldeSort}
    />
  )
}