import { IIpAlias } from "interfaces/IIpAlias";
import api from "../api";
import qs from "querystring";

export default {
  async getIpAliases(
    page = 0,
    limit = "15",
    search?: string,
    sort?: string,
    order?: string
  ): Promise<IIpAlias[]> {
    const query = qs.stringify({ page, limit, search, sort, order });
    const res = await api.get(`/ip-alias?${query}`);
    if (res.ok) {
      const data = await res.json();
      return data as IIpAlias[];
    }
    return [];
  },
  async addIpAlias(ipAlias: IIpAlias) {
    const res = await api.post("/ip-alias", { body: ipAlias });
    return res.ok;
  },
  async editIpAlias(oldIpAlias: IIpAlias, newIpAlias: IIpAlias) {
    const res = await api.put(`/ip-alias/${oldIpAlias._id}`, {
      body: newIpAlias,
    });
    return res.ok;
  },
  async deleteIpAlias(ipAlias: IIpAlias): Promise<boolean> {
    const res = await api.del(`/ip-alias/${ipAlias._id}`);
    return res.ok;
  },

  count: async (search: string) => {
    const res = await api.get(`/count/aliases?q=${search}`);
    const data = await res.json();
    return data;
  },
};
