import React, { useContext, useState } from 'react'
import { IConfiguration } from 'interfaces/IConfiguration'
import { IImage } from 'interfaces/IImage'
import { IIpAlias } from 'interfaces/IIpAlias'
import { IHospital } from 'interfaces/IHospital'
import { IDeviceInfo } from 'interfaces/iDeviceInfo'
import { ILog } from 'interfaces/ILog'
import { IMessage } from 'interfaces/IMessage'

type Resources = {
  hospitals: IHospital[]
  setHospitals: (hospitals: IHospital[]) => void
  images: IImage[]
  setImages: (images: IImage[]) => void
  configurations: IConfiguration[]
  setConfigurations: (configurations: IConfiguration[]) => void
  aliases: IIpAlias[]
  setAliases: (aliases: IIpAlias[]) => void
  unknown: IDeviceInfo[]
  setUnknown: (unknown: IDeviceInfo[]) => void
  logs: ILog[]
  setLogs: (logs: ILog[]) => void
  message: IMessage
  setMessage: (message: IMessage) => void
}
const ResourceContext = React.createContext<Resources>({} as any)

export const useResources = () => {
  return useContext(ResourceContext)
}

export const ResourceProvider: React.FC = props => {
  const [hospitals, setHospitals] = useState([] as IHospital[])
  const [images, setImages] = useState([] as IImage[])
  const [configurations, setConfigurations] = useState([] as IConfiguration[])
  const [aliases, setAliases] = useState([] as IIpAlias[])
  const [unknown, setUnknown] = useState([] as IDeviceInfo[])
  const [logs, setLogs] = useState([] as ILog[])
  const [message, setMessage] = useState({} as IMessage)

  return (
    <ResourceContext.Provider
      value={{
        hospitals,
        setHospitals,
        images,
        setImages,
        configurations,
        setConfigurations,
        aliases,
        setAliases,
        unknown,
        setUnknown,
        logs,
        setLogs,
        message,
        setMessage
      }}
    >
      {props.children}
    </ResourceContext.Provider>
  )

}
