import { useLocation, useNavigate } from '@reach/router'
import { ConfigurationForm } from 'components/configurations/ConfigurationForm'
import { useResources } from 'context/resource-provider'
import { IConfiguration } from 'interfaces/IConfiguration'
import { IDeviceInfo } from 'interfaces/iDeviceInfo'
import React, { useEffect, useState } from 'react'
import ConfigurationService from 'services/ConfigurationService'
import UnknownService from 'services/UnknownService'
import { DeleteConfirmation } from '../base/DeleteConfirmation'
import { UnknownPage } from './UnknownPage'

interface UnknownControllerProps {
  path?: string
}

export function UnknownController(props: UnknownControllerProps) {
  const { unknown, setUnknown, hospitals, images, setMessage } = useResources()
  const [isLoading, setIsLoading] = useState(true)
  const [activePage, setActivePage] = useState(1)
  const [showConfigurationForm, setShowConfigurationForm] = useState(false)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [configuration, setConfiguration] = useState<IConfiguration>()
  const navigate = useNavigate()
  const location = useLocation()
  const [search, setSearch] = useState('')
  const [deleteItem, setDeleteItem] = useState(null)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const [order, setOrder] = useState<'ascending' | 'descending'>(null);
  const [sort, setSort] = useState<string>('');

  const reloadUnkwowns = () => {
    setIsLoading(true)
    Promise.all([
      UnknownService.getUnknowns(
        activePage - 1,
        limit,
        search,
        sort,
        order === 'ascending' ? '-1' : '1'
      ),
      UnknownService.count(search),
    ])
      .then(([unknown, { count }]) => {
        setUnknown(unknown)
        const lastPage = Math.ceil(count / limit)
        setTotalPages(lastPage)
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    const state = location.state as { deviceInfo: IDeviceInfo }
    if (state && state.deviceInfo) {
      setConfiguration({
        deploy: false,
        hospital: undefined,
        image: undefined,
        mac: state.deviceInfo.mac,
        name: state.deviceInfo.name,
        manufacturer: state.deviceInfo.manufacturer,
        title: 'Create Configuration from Device',
        uefi: false,
        useLink: 'http',
      })
    }
  }, [location.state])

  useEffect(() => {
    reloadUnkwowns();
  }, [activePage, limit, search, order])

  function handleItemDelete(unknown: IDeviceInfo, e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setDeleteItem(unknown);
  }

  function handleConfirmDelete() {
    UnknownService.deleteUnknown(deleteItem._id).then(() => {
      setDeleteItem(null);
      reloadUnkwowns();
    })
  }

  const handleSearchChange = (s: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    const id = setTimeout(() => {
      setSearch(s)
    }, 200)
    setTimeoutId(id)
  }

  function handleItemSave(unknown: IDeviceInfo) {
    setConfiguration({
      deploy: false,
      hospital: hospitals[0],
      image: images[0],
      mac: unknown.mac,
      manufacturer: unknown.manufacturer,
      name: unknown.name,
      title: unknown.name,
      uefi: false,
      useLink: 'http',
    })

    setShowConfigurationForm(true)
  }

  function handleConfigurationSave(configuration: IConfiguration) {
    ConfigurationService.addConfiguration(configuration).then((response) => {
      setMessage({
        status: response?.id ? true : false,
        text: response?.id ? "Configuration Saved" : "Failed to save configuration",
        isNew: true
      });
      navigate('/configurations')
    })
  }

  const handlePaginationChange = (page: number) => {
    setActivePage(page)
  }

  const hanldeSort = (title: string) => {
    setSort(title);
    setOrder(order === 'ascending' ? 'descending' : 'ascending');
  }

  const handleLimitChange = perPage => {
    setLimit(perPage)
    setActivePage(1)
  }
  const Form = React.useCallback(
    () => (
      <ConfigurationForm
        formTitle='Create Configuration From Device'
        configuration={configuration}
        open={showConfigurationForm}
        onExit={() => setShowConfigurationForm(false)}
        onSave={handleConfigurationSave}
      />
    ),
    [configuration, showConfigurationForm]
  )

  return (
    <>
      <Form />
      <DeleteConfirmation
        title={"Unknown device " + (deleteItem ? deleteItem.name : '')}
        open={deleteItem != null}
        onExit={() => setDeleteItem(null)}
        onDelete={handleConfirmDelete}
      />
      <UnknownPage
        unknowns={unknown}
        onItemDelete={handleItemDelete}
        onItemSave={handleItemSave}
        configuration={configuration}
        isLoading={isLoading}
        onConfigurationSave={handleConfigurationSave}
        onPaginationChange={handlePaginationChange}
        activePage={activePage}
        totalPages={totalPages}
        limit={limit}
        onLimitChange={handleLimitChange}
        onSearchChange={handleSearchChange}
        order={order}
        sortTitle={sort}
        onSort={hanldeSort}
      />
    </>
  )
}
