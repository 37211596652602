import React from 'react'
import { Button, Checkbox, Table } from 'semantic-ui-react'
import { ILog } from 'interfaces/ILog'

interface ILogsListItemProps {
  log: ILog
  onClick?: (log: ILog) => void
  onRemove?: (log: ILog, e: React.MouseEvent<HTMLButtonElement>) => void
}

export function LogsListItem(props: ILogsListItemProps) {
  const { log, onRemove } = props

  return (
    <Table.Row
      onClick={() => props.onClick && props.onClick(log)}
      className={log.status + '-log'}
    >
      <Table.Cell colSpan={1} textAlign='center'>
        <Checkbox checked={log.checked ?? false} />
      </Table.Cell>
      <Table.Cell colSpan={2} singleLine>{log.ip}</Table.Cell>
      <Table.Cell colSpan={3} singleLine>{log.machine_name}</Table.Cell>
      <Table.Cell colSpan={5}>{log.message}</Table.Cell>
      <Table.Cell colSpan={2}>{log.status}</Table.Cell>
      <Table.Cell colSpan={3}>{new Date(log.created_at).toLocaleString('en-GB', { hour12: false })}</Table.Cell>
      <Table.Cell
        style={{ padding: 0 }}
        colSpan={1} textAlign='center'>
        <Button
          basic
          icon='close'
          compact
          color='grey'
          onClick={(e) => onRemove(log, e)}
        />
      </Table.Cell>
    </Table.Row >
  )
}
