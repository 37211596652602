import React, { useEffect, useState } from 'react'
import { LoginFormController } from '../login/LoginFormController'
import { PulseLoader } from 'react-spinners';
import authService from "../../services/AuthService";
import { useAuth } from "../../context/auth-provider";

export function ProtectedRoutes(props: any) {
  const [isLoading, setIsLoading] = useState(true)
  const auth = useAuth();

  useEffect(() => {
    setIsLoading(true);
    if (auth.isLoggedIn) {
      setIsLoading(false);
      return;
    }

    authService.checkLogin()
      .then((ok) => auth.setIsLoggedIn(ok))
      .catch(() => auth.setIsLoggedIn(false))
      .finally(() => setIsLoading(false));
  }, [auth]);

  return isLoading ? (
    <PulseLoader size={15} margin={2} color='#428BCA' />
  ) : auth.isLoggedIn ? (
    props.children
  ) : (
    <LoginFormController />
  )
}
