import { useState, useCallback, useMemo } from "react";
import { IImage } from "../interfaces/IImage";
export function useImage() {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [magnet, setMagnet] = useState("");

  const resetForm = useCallback(() => {
    setName("");
    setUrl("");
    setMagnet("");
  }, []);

  const setImage = useCallback((image: IImage) => {
    setName(image.name);
    setUrl(image.url);
    setMagnet(image.magnet);
  }, []);

  const isValid = useMemo(() => {
    return true;
  }, []);

  const image = useMemo(() => {
    return {
      name,
      url,
      magnet
    };
  }, [name, url, magnet]);

  return {
    image,
    name,
    setName,
    url,
    setUrl,
    magnet,
    setMagnet,
    resetForm,
    setImage,
    isValid
  };
}
