import React, { useEffect, useState } from 'react'
import { If, Then, Else } from 'react-if'
import { PageHeader } from 'components/base/PageHeader'
import { IIpAlias } from 'interfaces/IIpAlias'
import { AddIpAliasController } from './AddIpAliasFormController'
import { IpAliasListItemController } from './IpAliasListItemController'
import {
  Container,
  Loader,
  Pagination,
  Select,
  Table,
} from 'semantic-ui-react'
import { SearchForm } from 'components/base/SearchForm'

interface IpAliasPageProps {
  ipAliases: IIpAlias[]
  isLoading: boolean
  activePage: number
  totalPages: number
  limit: number
  order: 'ascending' | 'descending';
  sortTitle: string;
  onSort: (header: string) => void,
  setLimit: (x) => void
  onPagination: (e, p) => void
  onSearchChange: (q: string) => void
}
export function IpAliasPage(props: IpAliasPageProps) {
  const [isAddMode, setIsAddMode] = useState(false)
  const { ipAliases, isLoading } = props;
  const { onSort, order, sortTitle } = props;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container>
      <Loader active={isLoading} />
      <PageHeader
        title='IP Aliases'
        onCreateClick={() => {
          setIsAddMode(true)
        }}
      />

      <AddIpAliasController
        open={isAddMode}
        onClose={() => setIsAddMode(false)}
      />
      <SearchForm
        onSearchChange={props.onSearchChange}
        placeholderText='Search for title, from, to'
      />
      <If condition={ipAliases.length === 0}>
        <Then></Then>
        <Else>
          <Table celled fixed selectable sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  className='bg-primary-dark' colSpan={3}
                  sorted={sortTitle === 'title' ? order : null}
                  onClick={() => onSort('title')}
                >
                  Title
                </Table.HeaderCell>
                <Table.HeaderCell
                  className='bg-primary-dark' colSpan={6}
                  sorted={sortTitle === 'from' ? order : null}
                  onClick={() => onSort('from')}
                >
                  From
                </Table.HeaderCell>
                <Table.HeaderCell
                  className='bg-primary-dark' colSpan={7}
                  sorted={sortTitle === 'to' ? order : null}
                  onClick={() => onSort('to')}
                >
                  To
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {ipAliases.map(alias => {
                return (
                  <IpAliasListItemController ipAlias={alias} key={alias.from} />
                )
              })}
            </Table.Body>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <label style={{ marginRight: '0.3em' }}>Items per page: </label>
            <Select
              style={{ minWidth: '1em', height: '42px' }}
              options={[
                { text: '15', value: 15 },
                { text: '25', value: 25 },
                { text: '50', value: 50 },
              ]}
              value={props.limit}
              onChange={(e, select) => props.setLimit(select?.value)}
            />
            <Pagination
              style={{ boxShadow: '0 0' }}
              activePage={props.activePage}
              totalPages={props.totalPages}
              onPageChange={(e, p) => props.onPagination(e, p.activePage)}
            />
          </div>
        </Else>
      </If>
    </Container>
  )
}
