import React, { useState, useEffect } from "react";
import { IIpAlias } from "interfaces/IIpAlias";
import { useIpAlias } from "hooks/useIpAlias";
import { IpAliasForm } from "./IpAliasForm";
import { IPAliasListItem } from "components/base/IPAliasListItem";
import AliasService from "services/IpAliasService";
import { useResources } from "context/resource-provider";
import { DeleteConfirmation } from 'components/base/DeleteConfirmation';

interface IpAliasListItemControllerProps {
  ipAlias: IIpAlias;
}
export function IpAliasListItemController(
  props: IpAliasListItemControllerProps
) {
  const { ipAlias: originalIpAlias } = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const controls = useIpAlias();
  const { setAliases, setMessage } = useResources();
  const [deleteItem, setDeleteItem] = useState(null);

  useEffect(() => {
    controls.setIpAlias(originalIpAlias);
  }, [originalIpAlias]);

  const reloadAliases = () => {
    AliasService.getIpAliases().then(aliases => setAliases(aliases));
    setIsEditMode(false);
  }

  function handleEdit() {
    setIsEditMode(true);
  }

  function handleConfirmDelete() {
    setDeleteItem(originalIpAlias);
  }

  function handleDelete() {
    AliasService.deleteIpAlias(deleteItem).then((response) => {
      setMessage({
        status: response,
        text: response ? "Alias Deleted" : "Failed to delete alias",
        isNew: true
      });
      setDeleteItem(null);
      reloadAliases();
    });
  }

  async function handleSave(e) {
    e.preventDefault();
    const response = await AliasService.editIpAlias(originalIpAlias, controls.ipAlias);
    setMessage({
      status: response,
      text: response ? "Alias Saved" : "Failed to delete alias",
      isNew: true
    });
    reloadAliases();
  }

  function handleExit() {
    setIsEditMode(false);
  }

  return (
    <>
      <IpAliasForm
        formTitle="Edit Alias"
        {...controls}
        onSave={handleSave}
        onExit={handleExit}
        onDelete={handleConfirmDelete}
        open={isEditMode}
      />
      <DeleteConfirmation
        title={"Alias " + (deleteItem ? deleteItem.title : '')}
        open={deleteItem != null}
        onExit={() => setDeleteItem(null)}
        onDelete={handleDelete}
      />
      <IPAliasListItem
        onEdit={handleEdit}
        onDelete={handleDelete}
        ipAlias={originalIpAlias}
      />
    </>
  );
}
