import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";
import { If, Then } from "react-if";
import { Menu, Icon, Message, Transition, TransitionablePortal, Grid } from 'semantic-ui-react';
import logo from "../../assets/panacea_circle.png"
import { useAuth } from "context/auth-provider";
import AuthService from "services/AuthService";
import { useResources } from 'context/resource-provider';

interface NavbarProps {
  brand: string;
}

export default function Navbar(props: NavbarProps) {
  let location = window.location.pathname;
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const [activeItem, setActiveItem] = useState(location);
  const { message, setMessage } = useResources();
  const [openMenu, setOpenMenu] = useState(false);

  const handleItemClick = (name: string) => {
    setActiveItem(name);
    setOpenMenu(false);
  };

  const handleLogout = () => {
    AuthService.logout();
    setIsLoggedIn(false);
  }

  useEffect(() => {
    setTimeout(() => setMessage({ text: "", isNew: false }), 8000);
  }, [message])

  return (
    <Grid>
      <Grid.Row only="mobile">
        <Menu compact fixed='top' className="bg-primary-dark" inverted>
          <Menu.Item
            onClick={() => setOpenMenu(!openMenu)}
          >
            <img src={logo} className='logo-nav' alt='logo' />
          </Menu.Item >
          <Menu.Item name='Discovery Server' />
          <Menu.Item
            position="right"
            name='Logout'
            active={activeItem === '/logout'}
          >
            <Link onClick={handleLogout} to="/">
              <Icon name='log out' />
              Logout
            </Link>
          </Menu.Item>
        </Menu>
      </Grid.Row>
      <TransitionablePortal
        open={openMenu}
        transition={{
          animation: "slide down",
          duration: 500
        }}
        onClose={() => setOpenMenu(false)}
      >
        <Menu stackable fixed='top' className="bg-primary-dark" inverted style={{ marginTop: 53 }} >
          <If condition={isLoggedIn}>
            <Then>
              <Menu.Item
                as={Link} to="/configurations"
                name='Configurations'
                active={activeItem === '/configurations'}
                style={{ color: activeItem === '/configurations' ? '#ffffcc' : '#fff'! }}
                onClick={() => handleItemClick('/configurations')}
              >
                <Icon name='cogs' />
                Configurations
              </Menu.Item>
              <Menu.Item
                as={Link} to="/images"
                name='Images'
                active={activeItem === '/images'}
                onClick={() => handleItemClick('/images')}
              >
                <Icon name='disk' />
                Images
              </Menu.Item>

              <Menu.Item
                as={Link} to="/hospitals"
                name='Hospitals'
                active={activeItem === '/hospitals'}
                onClick={() => handleItemClick('/hospitals')}
              >
                <Icon name='hospital symbol' />
                Hospitals
              </Menu.Item>

              <Menu.Item
                as={Link} to="/aliases"
                name='Aliases'
                active={activeItem === '/aliases'}
                onClick={() => handleItemClick('/aliases')}
              >
                <Icon name='universal access' />
                Aliases
              </Menu.Item>
              <Menu.Item
                as={Link} to="/unknown"
                name='Unknown'
                active={activeItem === '/unknown'}
                onClick={() => handleItemClick('/unknown')}
              >
                <Icon name='question circle' />
                Unknown
              </Menu.Item>
              <Menu.Item
                as={Link} to="/logs"
                name='Logs'
                active={activeItem === '/logs'}
                onClick={() => handleItemClick('/logs')}
              >
                <Icon name='file alternate outline' />
                Logs
              </Menu.Item>

              <Menu.Item
                position="right"
                name='Logout'
                active={activeItem === '/logout'}
              >
                <Link onClick={handleLogout} to="/">
                  <Icon name='log out' />
                  Logout
                </Link>
              </Menu.Item>
            </Then>
          </If>
        </Menu>
      </TransitionablePortal>
      <Grid.Row only="tablet computer">
        <Menu stackable fixed='top' className="bg-primary-dark" inverted >
          <Menu.Item
            as={Link} to="/"
            name='Home'
            active={activeItem === 'Home'}
            onClick={() => window.location.replace('/')}
          >
            <img src={logo} className='logo-nav' alt='logo' />
          </Menu.Item >
          <Menu.Item name='Discovery Server' />
          <If condition={isLoggedIn}>
            <Then>
              <Menu.Item
                as={Link} to="/configurations"
                name='Configurations'
                active={activeItem === '/configurations'}
                style={{ color: activeItem === '/configurations' ? '#ffffcc' : '#fff'! }}
                onClick={() => handleItemClick('/configurations')}
              >
                <Icon name='cogs' />
                Configurations
              </Menu.Item>
              <Menu.Item
                as={Link} to="/images"
                name='Images'
                active={activeItem === '/images'}
                onClick={() => handleItemClick('/images')}
              >
                <Icon name='disk' />
                Images
              </Menu.Item>

              <Menu.Item
                as={Link} to="/hospitals"
                name='Hospitals'
                active={activeItem === '/hospitals'}
                onClick={() => handleItemClick('/hospitals')}
              >
                <Icon name='hospital symbol' />
                Hospitals
              </Menu.Item>

              <Menu.Item
                as={Link} to="/aliases"
                name='Aliases'
                active={activeItem === '/aliases'}
                onClick={() => handleItemClick('/aliases')}
              >
                <Icon name='universal access' />
                Aliases
              </Menu.Item>
              <Menu.Item
                as={Link} to="/unknown"
                name='Unknown'
                active={activeItem === '/unknown'}
                onClick={() => handleItemClick('/unknown')}
              >
                <Icon name='question circle' />
                Unknown
              </Menu.Item>
              <Menu.Item
                as={Link} to="/logs"
                name='Logs'
                active={activeItem === '/logs'}
                onClick={() => handleItemClick('/logs')}
              >
                <Icon name='file alternate outline' />
                Logs
              </Menu.Item>

              <Menu.Item
                position="right"
                name='Logout'
                active={activeItem === '/logout'}
              >
                <Link onClick={handleLogout} to="/">
                  <Icon name='log out' />
                  Logout
                </Link>
              </Menu.Item>
            </Then>
          </If>
        </Menu>

      </Grid.Row>
      <Transition animation="slide up" visible={message.isNew} duration={500}>
        <div style={{ position: "fixed", bottom: 0, width: "100%", marginBottom: 5, zIndex: 1000 }}>
          <Message
            style={{ margin: "0 auto", width: 300, textAlign: "center" }}
            content={message.text}
            className={message.status ? "success-log center aligned" : "error-log center aligned"}
            hidden={message.text === undefined || message.text === ""}
          />
        </div>

      </Transition>
    </Grid >
  );
}
