import React from 'react'
import { IConfiguration } from 'interfaces/IConfiguration'
import { Table } from 'semantic-ui-react'

interface ListItemProps {
  configuration: IConfiguration
}
export function ConfigurationListItem(props: ListItemProps) {
  const { configuration } = props
  return (
    <>
      <Table.Cell colSpan={4} singleLine>{configuration.hospital.name}</Table.Cell>
      <Table.Cell colSpan={5} singleLine>{configuration.title}</Table.Cell>
      <Table.Cell colSpan={4} singleLine>{configuration.name}</Table.Cell>
      <Table.Cell colSpan={3} singleLine>{configuration.mac}</Table.Cell>
    </>
  )
}
