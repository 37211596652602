import React from 'react'
import { ConfigurationForm } from './ConfigurationForm'
import { useResources } from 'context/resource-provider'
import ConfigurationService from '../../services/ConfigurationService'
import { IConfiguration } from 'interfaces/IConfiguration'

export interface AddConfigurationFormControllerProps {
  open: boolean
  onClose: any
  configuration?: IConfiguration
}

export function AddConfigurationFormController(
  props: AddConfigurationFormControllerProps
) {
  const { open, onClose, configuration } = props
  const { setConfigurations, setMessage } = useResources()
  const reloadConfigurations = () => {
    ConfigurationService.getConfigurations().then(configs =>
      setConfigurations(configs)
    )
  }

  async function handleSave(config: IConfiguration) {
    const response = await ConfigurationService.addConfiguration(config);
    setMessage({
      status: response?.ok === 1,
      text: response?.ok === 1 ? "Configuration Saved" : "Failed to save configuration",
      isNew: true
    });
    reloadConfigurations()
    onClose()
  }

  async function handleExit() {
    onClose()
  }

  return (
    <ConfigurationForm
      formTitle='Create New Configuration'
      configuration={configuration}
      onSave={handleSave}
      onExit={handleExit}
      open={open}
    />
  )
}
