import { IDeviceInfo } from "interfaces/iDeviceInfo";
import api from "../api";
import qs from "querystring";

export default {
  async getUnknowns(
    page = 0,
    limit = 15,
    search?: string,
    sort?: string,
    order?: string
  ): Promise<IDeviceInfo[]> {
    const query = qs.stringify({ page, limit, search, sort, order });
    const res = await api.get(`/unknown-devices?${query}`);
    if (!res.ok) {
      throw res;
    }
    const data = await res.json();
    return data as IDeviceInfo[];
  },
  async deleteUnknown(id: string) {
    const res = await api.del(`/unknown-devices/${id}`);
    if (res.ok) {
      return true;
    } else {
      return false;
    }
  },

  count: async (search: string) => {
    const res = await api.get(`/count/unknown?q=${search}`);
    const data = await res.json();
    return data;
  },
};
