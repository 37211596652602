import React, { useEffect, useState } from 'react'
import { FormEntry } from '../base/FormEntry'
import { BaseForm } from '../base/BaseForm'
import { FormSelect } from '../base/FormSelect'
import { Button, Form, Icon, Modal, Header } from 'semantic-ui-react'
import { IConfiguration } from 'interfaces/IConfiguration'
import { useConfiguration } from 'hooks/useConfiguration'
import { useResources } from 'context/resource-provider'
import { IDeviceInfo } from 'interfaces/iDeviceInfo'
import HospitalService from 'services/HospitalService'
import ImageService from 'services/ImageService'

interface ConfigurationFormProps {
  configuration?: IConfiguration
  formTitle: string
  newDevice?: IDeviceInfo
  open?: boolean
  onExit?: () => void
  onSave?: (configuration: IConfiguration) => void
  onDelete?: (configuration: IConfiguration) => void
}
export function ConfigurationForm(props: ConfigurationFormProps) {
  const configuration = useConfiguration(props.configuration);
  const [isLoading, setIsLoading] = useState(true);
  const {
    hospitals,
    images,
    setImages,
    setHospitals,
  } = useResources()
  const [entryTextName, setEntryText] = useState('');

  useEffect(() => {
    let mounted = true;
    setIsLoading(true)
    Promise.all([
      HospitalService.getHospitals(0, "100"),
      ImageService.getImages(0, '-1'),
    ])
      .then(([hospitals, images]) => {
        if (mounted) {
          setImages(images)
          setHospitals(hospitals)
        }

      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false));
  }, [setImages, setHospitals]);

  return (
    <Modal closeIcon open={props.open} onClose={props.onExit}>
      <Header color='grey'>
        <Icon name='cog' className='accent' />
        {props.formTitle}
      </Header>

      <Modal.Content>
        <BaseForm
          onSave={props.onSave}
          onExit={props.onExit}
          isValid={configuration.isValid}
          title={configuration.title}
        >
          <FormEntry
            autoFocus={true}
            label='Title'
            value={configuration.title}
            onChange={configuration.setTitle}
          />

          <Form.Group widths='equal'>
            <FormEntry
              label='Model'
              value={configuration?.name}
              placeholder='Name'
              onChange={configuration.setName}
            />
            <FormEntry
              label='Manufacturer'
              value={configuration.manufacturer}
              placeholder='HP'
              onChange={configuration.setManufacturer}
            />
          </Form.Group>

          {Object.entries(configuration.custom).map(entry => (
            <FormEntry
              key={entry[0]}
              label={entry[0]}
              value={entry[1]}
              onChange={configuration.setCustom}
            />
          ))}

          <Form.Group widths='equal'>
            <FormSelect
              loading={isLoading}
              data={hospitals}
              value={configuration.hospital?._id}
              onSelect={id =>
                configuration.setHospital(hospitals.find(h => h._id === id))
              }
              identifier='_id'
              displayName='name'
              label='Hospital'
            />
            <FormSelect
              loading={isLoading}
              data={images}
              value={configuration.image?._id}
              onSelect={id =>
                configuration.setImage(images.find(h => h._id === id))
              }
              identifier='_id'
              displayName='name'
              label='Image'
            />
          </Form.Group>

          <Form.Group widths='equal'>
            <FormEntry
              label='MAC'
              placeholder='00-00-00-00-00-00'
              value={configuration.mac}
              onChange={configuration.setMac}
            />
            <FormSelect
              data={[{ val: 'No' }, { val: 'Yes' }]}
              value={configuration.uefi ? 'Yes' : 'No'}
              onSelect={val =>
                configuration.setUefi(val === 'Yes' ? true : false)
              }
              identifier='val'
              displayName='val'
              label='UEFI'
            />
          </Form.Group>

          <Form.Group widths='equal'>
            <FormSelect
              data={[{ val: 'http' }, { val: 'magnet' }]}
              value={configuration.useLink || 'http'}
              onSelect={configuration.setUseLink}
              identifier='val'
              displayName='val'
              label='Use'
            />
            <FormSelect
              data={[{ val: 'No' }, { val: 'Yes' }]}
              value={configuration.deploy ? 'Yes' : 'No'}
              onSelect={val =>
                configuration.setDeploy(val === 'Yes' ? true : false)
              }
              identifier='val'
              displayName='val'
              label='Deploy'
            />
          </Form.Group>

          <Form.Group widths='equal'>
            <Form.Input
              label='Custom Field'
              value={entryTextName}
              type='text'
              onChange={e => setEntryText(e.target.value)}
              className='form-control'
              placeholder='Enter a custom field'
              action
            >
              <input />
              <Button
                onClick={e => {
                  e.preventDefault()
                  setEntryText('')
                  configuration.setCustom({})
                }}
              >
                <Icon name='plus' />
              </Button>
            </Form.Input>
          </Form.Group>

        </BaseForm>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='grey' onClick={props.onExit}>
          <Icon name='remove' /> Cancel
        </Button>
        {props.onDelete && (
          <Button
            basic
            color='orange'
            onClick={() =>
              props.onDelete && props.onDelete(configuration.toConfig())
            }
          >
            <Icon name='trash' /> Delete
          </Button>
        )}
        <Button
          basic
          color='green'
          onClick={() => props.onSave && props.onSave(configuration.toConfig())}
        >
          <Icon name='checkmark' /> Save
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
