import React, { useEffect, useState } from 'react'
import { If, Then, Else } from 'react-if'
import { LogsListItem } from './LogsListItem'
import { DatesRangeInput } from 'semantic-ui-calendar-react'
import {
  Button,
  Container,
  Dropdown,
  Header,
  Icon,
  Loader,
  Pagination,
  Select,
  Table,
} from 'semantic-ui-react'
import { ILog } from 'interfaces/ILog'
import { SearchForm } from 'components/base/SearchForm';

interface LogsPageProps {
  activePage: number
  isLoading: boolean
  limit: number
  order: 'ascending' | 'descending';
  sortTitle: string;
  datesRange: any;
  onDatesChange: (e, value) => void;
  onClearDate?: () => void;
  onSort: (header: string) => void,
  onItemDelete: (log: ILog, e: React.MouseEvent<HTMLButtonElement>) => void
  onDeleteMany: (log: ILog) => void
  onDeleteAll: () => void
  onPaginationChange: (page: number) => void
  onLimitChange: (limit: number) => void
  totalPages: number
  logs: ILog[];
  onExportCSV: (limit: number) => Promise<void>;
  onExportJSON: (limit: number) => Promise<void>;
  onSearchChange: (q: string) => void
  isDisabled: boolean;
  searchValue: string;
  onLogSelect: (Log: ILog) => void;
  onDeleteSelected: () => void;
  someSelected: boolean;
  selectAll: () => void;
  reloadLogs: () => void;
  resetSearch: boolean;
}

export const LogsPage = (props: LogsPageProps) => {
  const { onSort, order, sortTitle, datesRange, onDatesChange, onClearDate, logs, onExportCSV, onExportJSON, resetSearch,
    isDisabled, onItemDelete, isLoading, onDeleteMany, onSearchChange, onDeleteSelected, onDeleteAll, someSelected, selectAll, reloadLogs } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <Container>
      <Loader active={isLoading} />

      <If condition={logs.length === 0}>
        <Then>
          <Header as='h3' style={{ marginTop: '1em' }} color='grey'>
            No logs found
          </Header>
        </Then>
        <Else>
          <Header as='h3' style={{ marginTop: '1em' }} color='grey'>
            Current Logs
          </Header>
        </Else>
      </If>

      <SearchForm
        onSearchChange={onSearchChange}
        placeholderText='Search for IP, machine name, message, status, date'
        resetSearch={resetSearch}
      >
        <DatesRangeInput
          size="large"
          width={7}
          dateFormat='YYYY/MM/DD'
          placeholder='Date from - to'
          popupPosition='bottom right'
          className='calendar-input'
          closeOnMouseLeave={false}
          name='datesRange'
          closable
          hideMobileKeyboard
          clearable
          value={datesRange}
          iconPosition='right'
          autoComplete='off'
          onChange={onDatesChange}
          onClear={onClearDate}
          allowSameEndDate
        />
        <Button.Group
        >
          <Button icon='refresh' onClick={() => reloadLogs()} title="refresh" fluid />
          <Dropdown floating button className='icon' title="more actions" style={{ backgroundColor: "#bdbfc1" }} >
            <Dropdown.Menu className='left'>
              <Dropdown.Item text="Delete Filtered" disabled={isDisabled} compact={"true"} onClick={() => onDeleteMany(logs[0])} />
              <Dropdown.Item text="Delete Selected" disabled={!someSelected} compact={"true"} onClick={() => someSelected && onDeleteSelected()} />
              <Dropdown.Item text="Delete All Logs" disabled={logs.length === 0} compact={"true"} onClick={() => onDeleteAll()} />
              <Dropdown.Item text="Export CSV" icon="file alternate outline" onClick={() => onExportCSV(props.limit)} />
              <Dropdown.Item text="Export Json" icon="file alternate outline" onClick={() => onExportJSON(props.limit)} />
            </Dropdown.Menu>
          </Dropdown>
        </Button.Group>
      </SearchForm>

      <If condition={logs.length > 0}>
        <Then>
          <Table celled fixed compact sortable selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  textAlign='center'
                  className='bg-primary-dark hover' colSpan={1}
                  onClick={selectAll}
                  title="select all"
                >
                  &#10003;
                </Table.HeaderCell>
                <Table.HeaderCell
                  className='bg-primary-dark' colSpan={2}
                  sorted={sortTitle === 'ip' ? order : null}
                  onClick={() => onSort('ip')}
                >
                  IP
                </Table.HeaderCell>
                <Table.HeaderCell
                  className='bg-primary-dark' colSpan={3}
                  sorted={sortTitle === 'machine_name' ? order : null}
                  onClick={() => onSort('machine_name')}
                >
                  Machine Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  className='bg-primary-dark' colSpan={5}
                  sorted={sortTitle === 'message' ? order : null}
                  onClick={() => onSort('message')}
                >
                  Message
                </Table.HeaderCell>
                <Table.HeaderCell
                  className='bg-primary-dark' colSpan={2}
                  sorted={sortTitle === 'status' ? order : null}
                  onClick={() => onSort('status')}
                >
                  Status
                </Table.HeaderCell>
                <Table.HeaderCell
                  className='bg-primary-dark' colSpan={3}
                  sorted={sortTitle === 'created_at' ? order : null}
                  onClick={() => onSort('created_at')}
                >
                  Date
                </Table.HeaderCell>
                <Table.HeaderCell
                  textAlign='center'
                  // onClick={() => someSelected && onDeleteSelected()}
                  className={`bg-primary-dark`} colSpan={1}
                >
                  <Icon name='trash alternate outline' title="delete selected" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {logs.length !== 0 &&
                logs.map((log, index) => {
                  return (
                    <LogsListItem
                      key={index}
                      log={log}
                      onClick={props.onLogSelect}
                      onRemove={onItemDelete}
                    />
                  )
                })}
            </Table.Body>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <label style={{ marginRight: '0.3rem' }}>Items per page: </label>
            <Select
              style={{ minWidth: '1em', height: '42px' }}
              options={[
                { text: '15', value: 15 },
                { text: '25', value: 25 },
                { text: '50', value: 50 },
                { text: '100', value: 100 },
                { text: '200', value: 200 },
                { text: '500', value: 500 },
              ]}
              value={props.limit}
              onChange={(e, select) =>
                props.onLimitChange(parseInt(select.value as any))
              }
            />

            <Pagination
              style={{ boxShadow: '0 0' }}
              activePage={props.activePage}
              totalPages={props.totalPages}
              onPageChange={(e, p) =>
                props.onPaginationChange(parseInt(p.activePage as any))
              }
            />
          </div>
        </Then>
        <Else>
        </Else>
      </If>
    </Container >
  )
}

