import React from "react";

interface FormPasswordProps {
  label: string;
  value: any;
  onChange: any;
  autoFocus?: boolean;
}

export function FormPassword(props: FormPasswordProps) {
  const { autoFocus, onChange, label, value } = props;

  return (
    <>
      <div className="form-group">
        <label>
          <small>{label}</small>
        </label>
        <input
          type="password"
          autoFocus={autoFocus || false}
          value={value}
          onChange={e => {
            onChange(e.target.value);
          }}
          className="form-control"
        />
      </div>
    </>
  );
}
