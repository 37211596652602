import { join } from '../util/join';

type headers = { [key: string]: string };

export interface APIOptions {
  headers: headers;
}

export interface MessageAPIOptions extends APIOptions {
  body: any;
}

export class APIService {
  constructor(private baseUrl: string) {}

  async get(endpoint: string, options: Partial<APIOptions> = {}) {
    return await this.message('get', endpoint, options);
  }
  async post(endpoint: string, options: Partial<MessageAPIOptions>) {
    return await this.messageBody('post', endpoint, options);
  }
  async put(endpoint: string, options: Partial<MessageAPIOptions>) {
    return await this.messageBody('put', endpoint, options);
  }
  async del(endpoint: string, options: Partial<APIOptions> = {}) {
    return await this.message('delete', endpoint, options);
  }

  async message(
    method: string,
    endpoint: string,
    options: Partial<APIOptions> = {}
  ) {
    let { headers } = options;
    headers = this.defaultHeaders(headers);

    options = Object.assign(options, { headers });
    const fullOptions = { ...options, method };

    return await fetch(join(this.baseUrl, endpoint), fullOptions);
  }

  async messageBody(
    method: string,
    endpoint: string,
    options: Partial<MessageAPIOptions>
  ) {
    let { body, headers } = options;
    headers = this.defaultHeaders(headers);
    Object.assign(options, { headers });

    return await fetch(join(this.baseUrl, endpoint), {
      method,
      headers: headers,
      body: body ? JSON.stringify(body) : void 0,
    });
  }

  protected getToken() {
    return localStorage.getItem('token');
  }

  protected defaultHeaders(headers: headers = {}) {
    const token = this.getToken();
    return Object.assign(
      { 'content-type': 'application/json', Authorization: token },
      headers
    );
  }
}
