import { IConfiguration } from "../interfaces/IConfiguration";
import api from "../api";
import qs from "querystring";

const configToDTO = (config: IConfiguration) => {
  if (config._id) {
    delete config._id;
  }

  return {
    ...config,
    hospital: config.hospital._id,
    image: config.image._id,
  };
};

export default {
  getConfigurations: async (
    page = 0,
    limit = "15",
    search?: string,
    sort?: string,
    order?: string
  ) => {
    const query = qs.stringify({ page, limit, search, sort, order });
    const res = await api.get(`/configurations?${query}`);
    if (res.ok) {
      const data = await res.json();
      return data as IConfiguration[];
    }
    return [];
  },
  addConfiguration: async (configuration: IConfiguration) => {
    const res = await api.post("/configurations", {
      body: configToDTO(configuration),
    });
    const data = await res.json();
    return data;
  },
  editConfiguration: async (id: string, newConfig: IConfiguration) => {
    const res = await api.put(`/configurations/${id}`, {
      body: configToDTO(newConfig),
    });
    const data = await res.json();
    return data;
  },
  deleteConfiguration: async (id: string) => {
    const res = await api.del(`/configurations/${id}`);
    const data = await res.json();
    return data;
  },

  count: async (search: string) => {
    const res = await api.get(`/count/configurations?q=${search}`);
    const data = await res.json();
    return data;
  },
};
