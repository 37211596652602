import { useResources } from "context/resource-provider";
import React, { useEffect, useState } from "react";
import { IpAliasPage } from "./IpAliasesPage";
import AliasesService from "services/IpAliasService";

interface IIpAliasesControllerProps {
  path: string;
}

export function IpAliasesController(props: IIpAliasesControllerProps) {
  const { aliases, setAliases } = useResources();
  const [isLoading, setIsLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('')
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const [order, setOrder] = useState<'ascending' | 'descending'>(null);
  const [sort, setSort] = useState<string>('');

  useEffect(() => {
    setIsLoading(true)
    Promise.all([AliasesService.getIpAliases(
      activePage - 1,
      limit.toString(),
      search,
      sort,
      order === 'ascending' ? '-1' : '1'
    ),
    AliasesService.count(search)])
      .then(([aliases, { count }]) => {
        setAliases(aliases);
        const lastPage = Math.ceil(parseInt(count) / limit);
        setTotalPages(lastPage)
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false))
  }, [activePage, limit, search, order]);

  const handlePaginationChange = (e?, page?) => {
    setActivePage(page);
  }

  const hanldeSort = (title: string) => {
    setSort(title);
    setOrder(order === 'descending' ? 'ascending' : 'descending');
  }

  const handleLimitChange = (perPage) => {
    setLimit(perPage);
    setActivePage(1);
  }

  const handleSearchChange = (s: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    const id = setTimeout(() => {
      setSearch(s)
    }, 200)
    setTimeoutId(id)
  }

  return (
    <IpAliasPage
      ipAliases={aliases}
      isLoading={isLoading}
      onPagination={handlePaginationChange}
      activePage={activePage}
      totalPages={totalPages}
      limit={limit}
      setLimit={handleLimitChange}
      onSearchChange={handleSearchChange}
      order={order}
      sortTitle={sort}
      onSort={hanldeSort}
    />
  );
}
