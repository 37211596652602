import React from "react";
import { FormEntry } from "../base/FormEntry";
import { BaseForm } from "../base/BaseForm";
import { useHospital } from "hooks/useHospital";
import { Button, Header, Icon, Modal } from "semantic-ui-react";

type editControls = ReturnType<typeof useHospital>;

interface HospitalFormProps extends editControls {
  onSave?: any;
  onExit?: any;
  onDelete?: any;
  open?: boolean,
  formTitle: string
}

export function HospitalForm(props: HospitalFormProps) {
  const { name, setName, ip, setIp, onSave, onExit, onDelete, isValid, open, formTitle } = props;

  return (
    <Modal closeIcon
      open={open}
      onClose={onExit}
    >
      <Header color="grey">
        <Icon name='hospital symbol' className="accent" />
        {formTitle}
      </Header>
      <Modal.Content>
        <BaseForm title={name} onExit={onExit} onSave={onSave} isValid={isValid}>
          <FormEntry autoFocus={true} label="Name" value={name} placeholder="Hospital Name" onChange={setName} />
          <FormEntry label="IP" value={ip} placeholder="000.000.0.0" onChange={setIp} />
        </BaseForm>
      </Modal.Content >
      <Modal.Actions>
        <Button basic color='grey' onClick={onExit}>
          <Icon name='remove' /> Cancel
      </Button>
        {onDelete && <Button basic color='orange' onClick={onDelete}>
          <Icon name='trash' /> Delete
      </Button>}
        <Button basic color='green' onClick={onSave}>
          <Icon name='checkmark' /> Save
    </Button>
      </Modal.Actions>
    </Modal >
  );
}
