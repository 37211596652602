import React, { useEffect, useState } from "react";
import { Form, Icon, Input } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";

interface SearchFormProps {
  label?: string;
  value?: any;
  onSearchChange?: (s: string) => void;
  placeholder?: string;
  autoFocus?: boolean;
  placeholderText: string;
  children?: any;
  resetSearch?: boolean;
}

export function SearchForm(props: SearchFormProps) {
  const { onSearchChange, placeholderText, children, resetSearch } = props;
  const [input, setInput] = useState('');
  const [icon, setIcon] = useState<SemanticICONS>('search');

  const handleChange = (e) => {
    setIcon('close');
    setInput(e.target.value);
    if (e.target.value === '') {
      setIcon('search');
    }
  }

  useEffect(() => {
    setInput('');
  }, [resetSearch]);

  return (
    <Form
      onSubmit={e => e.preventDefault()}
      style={{ marginTop: '1em', backgroundColor: 'inherit' }}
    >
      <Form.Group widths='equal'>
        <Form.Input
          size='large'
          fluid
          iconPosition='left'
          placeholder={placeholderText}
          onChange={(e) => {
            onSearchChange(e.target.value)
            handleChange(e)
          }
          }
          value={input}
        >
          <Icon
            className={input ? "link" : null}
            name={icon}
            onClick={() => {
              setInput('');
              onSearchChange('');
              setIcon('search');
            }} />
          <input />
        </Form.Input>
        {children}
      </Form.Group>
    </Form>
  );
}
