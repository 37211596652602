import React from "react";
import { Form } from "semantic-ui-react";

interface FormEntryProps {
  label: string;
  value: any;
  onChange: any;
  placeholder?: string;
  autoFocus?: boolean;
}

export function FormEntry(props: FormEntryProps) {
  const { autoFocus, onChange, placeholder, label, value } = props;

  return (
    <>
      <Form.Input
        label={label}
        autoFocus={autoFocus || false}
        placeholder={placeholder || ""}
        value={value}
        onChange={e => {
          // setTextValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </>
  );
}
