import { IHospital } from "interfaces/IHospital";
import api from "../api";
import qs from "querystring";

export default {
  async getHospitals(
    page = 0,
    limit = "15",
    search?: string,
    sort?: string,
    order?: string
  ) {
    const query = qs.stringify({ page, limit, search, sort, order });
    const res = await api.get(`/hospitals?${query}`);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else return [];
  },
  async addHospital(hospital: IHospital) {
    const res = await api.post("/hospitals", { body: hospital });
    const data = await res.json();
    return data;
  },
  async editHospital(oldHospital: IHospital, newHospital: IHospital) {
    const res = await api.put(`/hospitals/${oldHospital._id}`, {
      body: newHospital,
    });
    const data = await res.json();
    return data;
  },
  async deleteHospital(hospital: IHospital) {
    const res = await api.del(`/hospitals/${hospital._id}`);
    const data = await res.json();
    return data;
  },
  count: async (search: string) => {
    const res = await api.get(`/count/hospitals?q=${search}`);
    const data = await res.json();
    return data;
  },
};
