import api from "../api";

export default {
  async checkLogin() {
    const res = await api.get("/auth/check");
    return res.ok;
  },
  async login(username, password) {
    // const res = await api.put("/auth/", { body: { username, password } });
    const res = await api.post("/auth/login", { body: { username, password } });
    const data = await res.json();
    if (res.ok) {
      localStorage.setItem("token", data.token);
    }
    return res.ok;
  },
  async logout() {
    localStorage.removeItem("token");
  },
};
