import { useState, useCallback, useMemo } from "react";
import { IIpAlias } from "../interfaces/IIpAlias";

export function useIpAlias() {
  const [title, setTitle] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const resetForm = useCallback(() => {
    setFrom("");
    setTo("");
    setTitle("");
  }, []);

  const setIpAlias = useCallback((alias: Partial<IIpAlias>) => {
    setFrom(alias.from);
    setTo(alias.to);
    setTitle(alias.title);
  }, []);

  const isValid = useMemo(() => {
    return true;
  }, []);

  const ipAlias = useMemo(() => {
    return {
      title,
      from,
      to
    };
  }, [title, from, to]);

  return {
    ipAlias,
    title,
    setTitle,
    from,
    setFrom,
    to,
    setTo,
    resetForm,
    setIpAlias,
    isValid
  };
}
