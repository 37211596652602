import React from "react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
import { BaseForm } from "../base/BaseForm";
import { FormEntry } from "../base/FormEntry";

interface IpAliasFormProps {
  onSave;
  onExit;
  onDelete?;
  from;
  to;
  title;
  setTitle;
  setFrom;
  setTo;
  open: boolean;
  formTitle;
}
export function IpAliasForm(props: IpAliasFormProps) {
  const { title, setTitle, onSave, onExit, onDelete, from, to, setFrom, setTo, open, formTitle } = props;
  return (
    <>
      <Modal closeIcon
        open={open}
        onClose={onExit}
      >
        <Header color="grey">
          <Icon name='universal access' className="accent" />
          {formTitle}
        </Header>
        <Modal.Content>
          <BaseForm title={title || "Untitled"} onSave={onSave} onExit={onExit} isValid={true}>
            <FormEntry label="Title" value={title} onChange={setTitle} autoFocus />
            <FormEntry label="From" value={from} onChange={setFrom} />
            <FormEntry label="To" value={to} onChange={setTo} />
          </BaseForm>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color='grey' onClick={onExit}>
            <Icon name='remove' /> Cancel
          </Button>
          {onDelete && <Button basic color='orange' onClick={onDelete}>
            <Icon name='trash' /> Delete
          </Button>}
          <Button basic color='green' onClick={onSave}>
            <Icon name='checkmark' /> Save
        </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
