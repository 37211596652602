import React from "react";
import { Button, Header, Grid } from 'semantic-ui-react';

interface IPageHeaderProps {
  title: string;
  onCreateClick: () => void;
}

export function PageHeader(props: IPageHeaderProps) {
  const { title, onCreateClick } = props;

  return (
    <Grid centered>
      <Grid.Row verticalAlign="middle" columns={2}>
        <Grid.Column>
          <Header as="h3" style={{ marginTop: '1em' }} color="grey">
            {title}
          </Header>
        </Grid.Column>
        <Grid.Column>
          <Button
            floated="right"
            basic color='grey'
            onClick={onCreateClick}
          >
            Create
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
