import React, { useState, useEffect } from 'react'
import { If, Then, Else } from 'react-if'
import { AddConfigurationFormController } from './AddConfigurationFormController'
import { ConfigurationListItemController } from './ConfigurationListItemController'
import { PageHeader } from '../base/PageHeader'
import { IDeviceInfo } from 'interfaces/iDeviceInfo'
import { IConfiguration } from 'interfaces/IConfiguration'
import {
  Container,
  Header,
  Loader,
  Pagination,
  Select,
  Table,
} from 'semantic-ui-react'
import { useResources } from 'context/resource-provider'
import { useLocation } from '@reach/router'
import { SearchForm } from 'components/base/SearchForm'

interface ConfigurationsPageProps {
  deviceInfo?: IDeviceInfo
  isLoading: boolean
  activePage: number
  totalPages: number
  limit: number
  order: 'ascending' | 'descending';
  sortTitle: string;
  onSort: (header: string) => void,
  setLimit: (x) => void
  onSearchChange: (q: string) => void
  onPagination: (e, p) => void
}

export const ConfigurationsPage = React.memo(
  (props: ConfigurationsPageProps) => {
    const { configurations, hospitals, images } = useResources()
    const [isAddMode, setIsAddMode] = useState(false);
    const location = useLocation();
    const [configuration, setConfiguration] = useState<IConfiguration>();
    const { onSort, order, sortTitle } = props;

    useEffect(() => {
      const state = location.state as any
      if (state && state?.deviceInfo) {
        console.log('setting device info state', state?.deviceInfo)
        setConfiguration({
          deploy: false,
          hospital: hospitals[0],
          image: images[0],
          mac: state.deviceInfo.mac,
          manufacturer: state.deviceInfo.manufacturer,
          name: state.deviceInfo.name,
          title: state.deviceInfo.name,
          uefi: false,
          useLink: 'http',
        })
        setIsAddMode(true)
      }
    }, [location.state])

    return (
      <Container>
        <Loader active={props.isLoading} />
        <PageHeader
          title='Configurations'
          onCreateClick={() => setIsAddMode(true)}
        ></PageHeader>
        <AddConfigurationFormController
          configuration={configuration}
          onClose={() => setIsAddMode(false)}
          open={isAddMode}
        />
        <SearchForm
          onSearchChange={props.onSearchChange}
          placeholderText='Search for hospital, model, mac'
        />
        <If condition={configurations.length === 0}>
          <Then>
            <Header as='h3' style={{ marginTop: '1em' }} color='grey'>
              No configurations found.
            </Header>
          </Then>
          <Else>
            <>
              <Table celled selectable fixed sortable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      colSpan={4} className='bg-primary-dark'
                      sorted={sortTitle === 'hospital' ? order : null}
                      onClick={() => onSort('hospital')}
                    >
                      Hospital
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan={5} className='bg-primary-dark'
                      sorted={sortTitle === 'title' ? order : null}
                      onClick={() => onSort('title')}
                    >
                      Title
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan={4} className='bg-primary-dark'
                      sorted={sortTitle === 'name' ? order : null}
                      onClick={() => onSort('name')}
                    >
                      Model
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan={3} className='bg-primary-dark'
                      sorted={sortTitle === 'mac' ? order : null}
                      onClick={() => onSort('mac')}
                    >
                      MAC
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {configurations.map(config => (
                    <ConfigurationListItemController
                      key={config._id}
                      configuration={config}
                    />
                  ))}
                </Table.Body>
              </Table>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <label style={{ marginRight: '0.3rem' }}>
                  Items per page:{' '}
                </label>
                <Select
                  style={{ minWidth: '1em', height: '42px' }}
                  options={[
                    { text: '15', value: 15 },
                    { text: '25', value: 25 },
                    { text: '50', value: 50 },
                  ]}
                  value={props.limit}
                  onChange={(e, select) => props.setLimit(select?.value)}
                />
                <Pagination
                  style={{ boxShadow: '0 0' }}
                  activePage={props.activePage}
                  totalPages={props.totalPages}
                  onPageChange={(e, p) => props.onPagination(e, p.activePage)}
                />
              </div>
            </>
          </Else>
        </If>
      </Container>
    )
  }
)
