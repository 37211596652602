import React, { useState } from "react";
import { LoginForm } from "./LoginForm";
import { useAuth } from "../../context/auth-provider";
import AuthService from "services/AuthService";

export function LoginFormController() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const auth = useAuth();

  async function login(e) {
    e.preventDefault();
    const ok = await AuthService.login(username, password);
    auth.setIsLoggedIn(ok);
    clearForm();
    if (!auth.isLoggedIn) {
      setErrorMessage("Invalid username or password.");
    }
  }

  function clearForm() {
    setPassword("");
  }

  return (
    <LoginForm
      username={username}
      password={password}
      errorMessage={errorMessage}
      onUsernameChange={setUsername}
      onPasswordChange={setPassword}
      onLogin={login}
    />
  );
}
