import React, { useState, useContext } from "react";

type Login = {
    isLoggedIn,
    setIsLoggedIn
}

const AuthContext = React.createContext<Login>({} as any);

export const useAuth = () => {
    return useContext(AuthContext)
}

export const AuthProvider: React.FC = props => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    return (
        <AuthContext.Provider
            value={{ isLoggedIn, setIsLoggedIn }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}
