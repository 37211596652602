import React from "react";
import { ImageForm } from "./ImageForm";
import { useImage } from "hooks/useImage";
import ImageService from "../../services/ImageService";
import { useResources } from "context/resource-provider";

interface AddImageFormControllerProps {
  open: boolean;
  onClose: any;
}
export function AddImageFormController(props: AddImageFormControllerProps) {
  const { open, onClose } = props;
  const { setImages, setMessage } = useResources();
  const controls = useImage();

  async function handleSave(e: any) {
    e.preventDefault();
    const response = await ImageService.addImage(controls.image);
    setMessage({
      status: response?.id ? true : false,
      text: response?.id ? "New Iamge Created" : "Failed to Create Image",
      isNew: true
    });
    ImageService.getImages().then(images => setImages(images));
    controls.resetForm();
    onClose();
  }

  async function handleExit(e: any) {
    e.preventDefault();
    controls.resetForm();
    onClose();
  }

  return (
    <ImageForm
      formTitle='Add Image'
      {...controls} onSave={handleSave} onExit={handleExit} open={open} />
  );
}
