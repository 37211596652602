import React, { useState, useMemo, useEffect } from "react";
import { ImageForm } from "./ImageForm";
import { IImage } from "../../interfaces/IImage";
import { useImage } from "hooks/useImage";
import { ImageListItem } from "components/base/ImageListItem";
import ImageService from "../../services/ImageService";
import { useResources } from "context/resource-provider";
import { DeleteConfirmation } from 'components/base/DeleteConfirmation';

interface ImageListItemControllerProps {
  image: IImage;
}
export function ImageListItemController(props: ImageListItemControllerProps) {
  const { image } = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const { setImages, setMessage } = useResources();
  const [deleteItem, setDeleteItem] = useState(null);

  const controls = useImage();

  useEffect(() => {
    controls.setImage(image);
  }, [image]);

  const newImage = useMemo(() => {
    return Object.assign({}, controls.image);
  }, [image, controls.image]);

  const reloadImages = () => {
    ImageService.getImages().then(images => setImages(images));
    setIsEditMode(false);
  }

  function handleEdit() {
    setIsEditMode(true);
  }

  function handleConfirmDelete() {
    setDeleteItem(image);
  }

  async function handleDelete() {
    const response = await ImageService.deleteImage(deleteItem);
    setMessage({
      status: response?.ok === 1,
      text: response?.ok === 1 ? "Image Deleted" : "Failed to delete image",
      isNew: true
    });
    setDeleteItem(null);
    reloadImages();
  }

  async function handleSave(e) {
    e.preventDefault();
    const response = await ImageService.editImage(image, newImage);
    setMessage({
      status: response?.ok === 1,
      text: response?.ok === 1 ? "Image Saved" : "Failed to save image",
      isNew: true
    });
    controls.resetForm();
    reloadImages();
  }

  function handleExit() {
    setIsEditMode(false);
  }

  return (
    <>
      <ImageForm
        {...controls}
        formTitle="Edit Image"
        onSave={handleSave}
        onExit={handleExit}
        open={isEditMode}
        onDelete={handleConfirmDelete}
      />
      <DeleteConfirmation
        title={"Image " + (deleteItem ? deleteItem.name : '')}
        open={deleteItem != null}
        onExit={() => setDeleteItem(null)}
        onDelete={handleDelete}
      />
      <ImageListItem
        image={image}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    </>
  );
}
