import React, { useState, useEffect } from 'react'
import { AddImageFormController } from './AddImageFormController'
import { ImageListItemController } from './ImageListItemController'
import { If, Then, Else } from 'react-if'
import { PageHeader } from '../base/PageHeader'
import {
  Container,
  Header,
  Loader,
  Pagination,
  Select,
  Table,
} from 'semantic-ui-react'
import { useResources } from '../../context/resource-provider'
import { SearchForm } from '../../components/base/SearchForm'

interface ImagesPageProps {
  path?: string
  isLoading: boolean
  activePage: number
  totalPages: number
  limit: number
  order: 'ascending' | 'descending';
  sortTitle: string;
  onSort: (header: string) => void,
  setLimit: (x) => void
  onPagination: (e, p) => void
  onSearchChange: (q: string) => void
}
export default function ImagesPage(props: ImagesPageProps) {
  const [isAddMode, setIsAddMode] = useState(false)
  const { images } = useResources();
  const { onSort, order, sortTitle } = props;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container>
      <Loader active={props.isLoading} />
      <PageHeader
        title='Images'
        onCreateClick={() => setIsAddMode(true)}
      />

      <AddImageFormController
        onClose={() => setIsAddMode(false)}
        open={isAddMode}
      />
      <SearchForm
        onSearchChange={props.onSearchChange}
        placeholderText='Search for name, url, magnet'
      />
      <If condition={images.length === 0}>
        <Then>
          <Header as='h3' style={{ marginTop: '1em' }} color='grey'>
            No images found.
          </Header>
        </Then>
        <Else>
          <Table celled fixed selectable sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  className='bg-primary-dark' colSpan={3}
                  sorted={sortTitle === 'name' ? order : null}
                  onClick={() => onSort('name')}
                >
                  Title
                </Table.HeaderCell>
                <Table.HeaderCell
                  className='bg-primary-dark' colSpan={6}
                  sorted={sortTitle === 'url' ? order : null}
                  onClick={() => onSort('url')}
                >
                  URL
                </Table.HeaderCell>
                <Table.HeaderCell
                  className='bg-primary-dark' colSpan={7}
                  sorted={sortTitle === 'magnet' ? order : null}
                  onClick={() => onSort('magnet')}
                >
                  Magnet
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {images.map(image => (
                <ImageListItemController key={image._id} image={image} />
              ))}
            </Table.Body>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <label style={{ marginRight: '0.3rem' }}>Items per page: </label>
            <Select
              style={{ minWidth: '1em', height: '42px' }}
              options={[
                { text: '15', value: 15 },
                { text: '25', value: 25 },
                { text: '50', value: 50 },
              ]}
              value={props.limit}
              onChange={(e, select) => props.setLimit(select?.value)}
            />
            <Pagination
              style={{ boxShadow: '0 0' }}
              activePage={props.activePage}
              totalPages={props.totalPages}
              onPageChange={(e, p) => props.onPagination(e, p.activePage)}
            />
          </div>
        </Else>
      </If>
    </Container>
  )
}
