import { ILog } from 'interfaces/ILog';
import api from '../api';
import qs from 'querystring';

export default {
	async getLogs(
		page = 0,
		limit = 15,
		search?: string,
		sort?: string,
		order?: string,
		from?: string,
		to?: string
	): Promise<ILog[]> {
		const queryParams = { page, limit, search, sort, order, from, to };
		if (from === '') delete queryParams.from;
		if (to === '') delete queryParams.to;
		const query = qs.stringify(queryParams);
		const res = await api.get(`/logs?${query}`);
		if (!res.ok) {
			throw res;
		}
		const data = await res.json();
		return data as ILog[];
	},

	async getLogsCSV(
		page = 0,
		limit = 15,
		search?: string,
		sort?: string,
		order?: string,
		from?: string,
		to?: string
	) {
		const queryParams = { page, limit, search, sort, order, from, to };
		if (from === '') delete queryParams.from;
		if (to === '') delete queryParams.to;
		const query = qs.stringify(queryParams);
		const res = await api.get(`/logs/csv?${query}`);
		if (!res.ok) {
			throw res;
		}
		const blob = await res.blob();
		const url = window.URL.createObjectURL(new Blob([blob]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute(
			'download',
			`logs${(search ? '-' + search : '') +
			(from ? '-' + from : '') +
			(to ? '-' + to : '')
			}.csv`
		);
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
		return res;
	},

	async getLogsJSON(
		page = 0,
		limit = 15,
		search?: string,
		sort?: string,
		order?: string,
		from?: string,
		to?: string
	) {
		const queryParams = { page, limit, search, sort, order, from, to };
		if (from === '') delete queryParams.from;
		if (to === '') delete queryParams.to;
		const query = qs.stringify(queryParams);
		const res = await api.get(`/logs/json?${query}`);
		if (!res.ok) {
			throw res;
		}
		const blob = await res.blob();
		const url = window.URL.createObjectURL(new Blob([blob]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute(
			'download',
			`logs${(search ? '-' + search : '') +
			(from ? '-' + from : '') +
			(to ? '-' + to : '')
			}.json`
		);
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
		return res;
	},

	async deleteLogsFromTo(search: string, from?: string, to?: string) {
		const query = qs.stringify({ search, from, to });
		const res = await api.del(`/logs?${query}`);
		if (!res.ok) {
			throw res;
		}
		const data = await res.json();
		return data;
	},

	async deleteLogsList(logsList: string[]) {
		const res = await api.post(`/logs/delete-selected`, { body: logsList });
		if (!res.ok) {
			throw res;
		}
		const data = await res.json();
		return data;
	},

	async deleteAll() {
		const res = await api.del('/logs/delete-all');
		if (!res.ok) {
			throw res;
		}
		const data = await res.json();
		return data;
	},

	async deleteLog(id: string) {
		const res = await api.del(`/logs/${id}`);
		if (res.ok) {
			return true;
		} else {
			return false;
		}
	},

	count: async (search: string, from?: string, to?: string) => {
		const queryParams = { search, from, to };
		if (from === '') delete queryParams.from;
		if (to === '') delete queryParams.to;
		if (search === '') delete queryParams.search;
		const query = qs.stringify(queryParams);
		const res = await api.get(`/count/logs?${query}`);
		const body = res.body;
		const decoderStream = new TextDecoderStream();
		const reader =
			body
				.pipeThrough(decoderStream)
				.getReader();

		let result = "";
		while (true) {
			const { done, value } = await reader.read();
			if (done)
				break;
			result += value;
		}

		let data: any;
		try {
			data = JSON.parse(result);
		} catch {
			data = { count: 0 };
		}

		return data;
	},
};

