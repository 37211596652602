import React from 'react'
import { DropdownItemProps, Form } from 'semantic-ui-react'

interface FormSelectProps {
  label: string
  data: any[]
  identifier: string
  displayName: string
  onSelect: (data: any) => void
  loading?: boolean,
  value: any
}
export function FormSelect(props: FormSelectProps) {
  const { label, data, identifier, displayName, onSelect, value, loading } = props

  return (
    <Form.Select
      label={label}
      value={value}
      loading={loading}
      onChange={(e, option) => {
        onSelect(option.value)
      }}
      options={data.map<DropdownItemProps>(item => ({
        value: item[identifier],
        key: item[identifier],
        text: item[displayName],
      }))}
    />
  )
}
