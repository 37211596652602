import { useState } from 'react'
import { IConfiguration } from '../interfaces/IConfiguration'
import { useResources } from 'context/resource-provider'
import { IDeviceInfo } from 'interfaces/iDeviceInfo'

export function useConfiguration(configuration?: IConfiguration) {
  const { hospitals, images } = useResources()
  const [title, setTitle] = useState(configuration?.title || 'Untitled')
  const [name, setName] = useState(configuration?.name || '')
  const [mac, setMac] = useState(configuration?.mac || '')
  const [manufacturer, setManufacturer] = useState(
    configuration?.manufacturer || ''
  )
  const [useLink, setUseLink] = useState(configuration?.useLink || 'http')
  const [uefi, setUefi] = useState(configuration?.uefi || false)
  const [deploy, setDeploy] = useState(configuration?.deploy || false)
  const [hospital, setHospital] = useState(
    configuration?.hospital || hospitals[0]
  )
  const [image, setImage] = useState(configuration?.image || images[0])
  const [custom, setCustom] = useState(configuration?.customFields || {})

  const isValid =
    title !== '' &&
    hospital !== void 0 &&
    image !== void 0 &&
    name !== '' &&
    manufacturer !== '' &&
    mac !== ''

  const setConfiguration = (config: IConfiguration) => {
    setTitle(config.title)
    setUseLink(config.useLink)
    setDeploy(config.deploy)
    setName(config.name)
    setManufacturer(config.manufacturer)
    setMac(config.mac)
    setHospital(config.hospital)
    setImage(config.image)
    setCustom(config.customFields || {})
  }

  const toConfig = (): IConfiguration => ({
    _id: configuration?._id,
    name,
    mac,
    title,
    useLink,
    manufacturer,
    deploy,
    image,
    hospital,
    uefi,
  })

  const resetConfiguration = () => {
    setTitle('Untitled')
    setUseLink('http')
    setDeploy(false)
    setName('')
    setManufacturer('')
    setMac('')
    setHospital(hospitals[0])
    setImage(images[0])
    setCustom({})
  }

  const setDeviceInfo = (deviceInfo: IDeviceInfo) => {
    setName(deviceInfo.name)
    setManufacturer(deviceInfo.manufacturer)
    setMac(deviceInfo.mac)
    setHospital(hospitals.find(h => h.ip === deviceInfo.ip))
  }

  return {
    toConfig,
    title,
    name,
    manufacturer,
    mac,
    uefi,
    useLink,
    deploy,
    custom,
    hospital,
    image,
    setTitle,
    setName,
    setManufacturer,
    setUefi,
    setMac,
    setUseLink,
    setDeploy,
    setCustom,
    setHospital,
    setImage,
    isValid,
    setConfiguration,
    resetConfiguration,
    setDeviceInfo,
  }
}
