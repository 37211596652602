import React from 'react';
import { HospitalForm } from './HospitalForm';
import { useHospital } from '../../hooks/useHospital';
import hospitalService from '../../services/HospitalService';
import { useResources } from '../../context/resource-provider';

interface AddHospitalFormControllerProps {
  open: boolean
  onClose: any
}
export function AddHospitalFormController(
  props: AddHospitalFormControllerProps
) {
  const { open, onClose } = props

  const controls = useHospital()
  const { setHospitals, setMessage } = useResources()

  async function handleSave(e: any) {
    e.preventDefault()
    const response = await hospitalService.addHospital(controls.hospital);
    setMessage({
      status: response?.id ? true : false,
      text: response?.id ? "Hospital Saved" : "Failed to save hospital",
      isNew: true
    });
    const hospitals = await hospitalService.getHospitals()
    setHospitals(hospitals);
    controls.resetForm()
    onClose()
  }

  async function handleExit(e: any) {
    e.preventDefault()
    controls.resetForm()
    onClose()
  }

  return (
    <HospitalForm
      formTitle='Add New Hospital'
      {...controls}
      onSave={handleSave}
      onExit={handleExit}
      open={open}
    />
  )
}
